import { SuccessFilled } from '@element-plus/icons-vue'
import { ElNotification } from 'element-plus'
import { getCombinedUserNames } from 'shared/utils'
import { GROUP_CODE } from 'shared/utils/constants'

import { globalI18n } from '@/locales'
import { useCommonStore } from '@/stores/commons'
import { CommonCode, DrafterParseType } from '@/types'

export const parseDrafter = (payload: DrafterParseType) => {
  const { positionCode, employeeKoreanName, employeeEnglishName, englishName, koreanName } =
    payload ?? {}
  if (!positionCode) return ''
  const positionName = getPositionName(positionCode)
  const kName = employeeKoreanName ?? koreanName ?? ''
  const eName = employeeEnglishName ?? englishName ?? ''
  if (!kName && !englishName) return positionName
  return `${positionName} (${getCombinedUserNames(kName, eName)})`
}

export const getPositionName = (positionCode: string): string => {
  if (!positionCode) return ''

  const commonCodes = useCommonStore().commonCodes
  const position = commonCodes[GROUP_CODE.POSTN_CD]?.find(
    (item: CommonCode) => item.vmsCommonCode === positionCode
  )
  return position?.vmsCommonName ?? ''
}

export const showRequiredFieldToast = (message: string) => {
  setTimeout(() => {
    ElNotification({
      title: globalI18n.t('common.messages.error'),
      message: `${message} ${globalI18n.t('common.messages.requiredField')}`,
      position: 'top-left',
      type: 'error',
    })
  }, 0)
}

export const jasperReport = () => {
  useCommonStore().getFiles(1)
}

export const successAlert = (message: string) => {
  ElNotification({
    message: globalI18n.t(message),
    type: 'success',
  })
}

export const showSuccessNotification = (msg: string) => {
  ElNotification.success({
    icon: SuccessFilled,
    message: msg,
    customClass: 'complete-message',
    showClose: false,
  })
}

export const showErrorNotification = (message: string, title?: string) => {
  ElNotification.error({
    title: title ?? globalI18n.t('common.messages.error'),
    message,
    position: 'top-left',
  })
}

export const notifySuccess = (successMessage: string) => {
  ElNotification({
    title: globalI18n.t('common.messages.success'),
    message: successMessage,
    customClass: 'success-message',
    type: 'success',
    position: 'top-right',
    duration: 3000,
  })
}
